import axios from 'axios'
import { request } from './request.js'


//获取阿里云OSS相关配置
export function getAliyunOssinfo (method) {
  return request({
    url: '/common/oss-upload-init',
    method,
  })
}

//通过获取相关配置，开始上传图片
export function uploadFile (images) {
  return new Promise((resolve, reject) => {  
    getAliyunOssinfo('GET').then((res) => {
      // console.log("123123123",res);
      const { accessid, policy, signature, dir, host } = res.data.data
      const key = dir + images.uid + images.name;
      const formData = new FormData()
      formData.append('key', key)
      formData.append('OSSAccessKeyId', accessid)
      formData.append('policy', policy)
      formData.append('signature', signature)
      formData.append('success_action_status', '200')
      formData.append('file', images)
      axios.post(host, formData).then((res) => {
        const { status } = res
        if (status === 200) {
          const data = host + "/" + key;
          resolve(data)
        } else {

          reject(res)
        }
      }).catch(err => {

        reject(err)
      })
    }).catch(err => {})
  })
}
